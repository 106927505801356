import { services } from "../../../../../config";

interface IPorts {
  9091: string;
  8000: string;
}

export const getOrCreateExecutionService = async (
  sessionId: string | undefined,
  userId: string | undefined,
): Promise<IPorts | undefined> => {
  if (sessionId == null || userId == null) {
    return undefined;
  }
  const response = await fetch(`${services.SWARM_MANAGER_API}/createService`, {
    method: "POST",
    body: JSON.stringify({
      sessionId,
      userId,
    }),
    headers: {
      "Content-type": "application/json",
    },
    credentials: "include",
  });

  const createdService = await response.json().then((data) => {
    return data.ports;
  });
  return createdService;
};

export const restartExecutionService = async (
  sessionId: string | undefined,
  userId: string | undefined,
): Promise<IPorts | undefined> => {
  console.log("Restarting service", sessionId, userId);
  if (sessionId == null || userId == null) {
    return undefined;
  }
  const response = await fetch(`${services.SWARM_MANAGER_API}/restartService`, {
    method: "POST",
    body: JSON.stringify({
      sessionId,
      userId,
    }),
    headers: {
      "Content-type": "application/json",
    },
    credentials: "include",
  });

  const createdService = await response.json().then((data) => {
    return data.ports;
  });
  return createdService;
};
