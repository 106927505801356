import {
  faAngleDown,
  faAngleUp,
  faPlus,
  faRefresh,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  ChamTerminal,
  ITerminal,
  TERMINAL_BACKGROUND,
  TERMINAL_FOREGROUND,
} from "../ChamTerminal";
import styles from "./ChamIDEContainer.module.css";
import { Terminal } from "xterm";
import { Tooltip } from "@mui/material";
import { useTerminalSession } from "../../contexts/TerminalSessionContext/TerminalSessionContext";
import { TerminalMenu } from "./TerminalMenu";
import { useLessonId } from "../../../LessonContext/LessonContext";
import { createSession } from "../utils/createSession";
import { getUserSessionForLesson } from "../utils/getUserSessionForLesson";
import { useDirectoryTree } from "../../contexts/DirectoryContext/DirectoryContext";
import { getSessionDirectory } from "../utils/getSessionDirectory";

interface TerminalContainerProps {
  setError: (error: string) => void;
  setShowTerminal?: (show: boolean) => void;
  showTerminal?: boolean;
}

export const TerminalContainer: React.FC<TerminalContainerProps> = ({
  setError,
  setShowTerminal: setShowTerminalProp,
  showTerminal: showTerminalProp,
}) => {
  const lessonId = useLessonId();
  const [terminals, setTerminals] = React.useState<ITerminal[]>([]);
  const [activeTerminal, setActiveTerminal] = React.useState<ITerminal>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [shouldRestartTerminal, setShouldRestartTerminal] =
    React.useState<boolean>(false);
  const { terminalSessionId: sessionId, setTerminalSessionId } =
    useTerminalSession();
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      if (sessionId) {
        setAnchorEl(event.currentTarget);
      }
    },
    [sessionId],
  );

  const handleAddTerminal = React.useCallback(async (): Promise<void> => {
    if (!sessionId && lessonId) {
      const maybeSessionId = await getUserSessionForLesson(lessonId);
      if (!maybeSessionId) {
        const sessionId = await createSession(lessonId);
        setTerminalSessionId?.(sessionId);
      } else {
        setTerminalSessionId?.(maybeSessionId);
      }
    }
    const terminal = new Terminal({
      theme: {
        background: TERMINAL_BACKGROUND,
        foreground: TERMINAL_FOREGROUND,
        cursor: TERMINAL_FOREGROUND,
        selectionBackground: TERMINAL_FOREGROUND,
        selectionForeground: TERMINAL_BACKGROUND,
      },
      cursorBlink: true,
      rows: Math.ceil((screen.height * 0.25) / 17),
      cols: 80,
    });
    let termName = `Bash terminal ${terminals.length + 1}`;
    terminals.forEach((term) => {
      if (term.name === termName) {
        termName += "_1";
      }
    });
    const newTerminal: ITerminal = {
      name: termName,
      id: crypto.randomUUID(),
      terminal,
    };
    setTerminals([...terminals, newTerminal]);
    setActiveTerminal(newTerminal);
  }, [terminals, lessonId, sessionId, setTerminals, setActiveTerminal]);
  const { setDirectoryTree } = useDirectoryTree();

  React.useEffect(() => {
    const getSessionId = async (): Promise<void> => {
      if (lessonId != null && setTerminalSessionId != null && !sessionId) {
        const sessionId = await getUserSessionForLesson(lessonId);
        if (sessionId && sessionId !== "null") {
          setTerminalSessionId(sessionId);
        }
      }
    };
    void getSessionId();
  }, [lessonId, setTerminalSessionId, sessionId]);

  React.useEffect(() => {
    const getDir = async (): Promise<void> => {
      if (sessionId && setDirectoryTree) {
        try {
          const directoryTree = await getSessionDirectory(sessionId);
          setDirectoryTree(directoryTree);
        } catch (e: any) {
          setError(e.message);
        }
      }
    };
    void getDir();
  }, [sessionId, setDirectoryTree]);

  const [showTerminalState, setShowTerminalState] = React.useState(false);

  const showTerminal = React.useMemo(
    () => showTerminalProp ?? showTerminalState,
    [showTerminalProp, showTerminalState],
  );

  const setShowTerminal = React.useMemo(
    () => setShowTerminalProp ?? setShowTerminalState,
    [setShowTerminalProp, setShowTerminalState],
  );

  const handleToggleTerminal = React.useCallback(() => {
    setShowTerminal(!showTerminal);
  }, [showTerminal, setShowTerminal]);

  return (
    <>
      <div className={styles.textInputBottomBar}>
        <div className={styles.heading}>
          <div className={styles.headingContent}>
            {shouldRestartTerminal ? (
              <Tooltip title="Terminal restart in progress">
                <FontAwesomeIcon className={styles.spinner} icon={faSpinner} />
              </Tooltip>
            ) : (
              <Tooltip
                className={styles.minimizeIcon}
                onClick={() => {
                  if (
                    window.confirm(
                      "NOTE: DO NOT RESTART UNLESS YOU NEED TO i.e. YOU RUN INTO STALE FILE HANDLES.\nRestarting the terminal will cause externally installed applications to be cleared.\nIf you do restart, please re-install all externally installed applications.",
                    )
                  ) {
                    setShouldRestartTerminal(true);
                  }
                }}
                title="Restart Terminal"
              >
                <FontAwesomeIcon icon={faRefresh} />
              </Tooltip>
            )}
            TERMINAL
          </div>
          <div className={styles.rightIcon}>
            {terminals.length > 0 && activeTerminal?.id && (
              <Tooltip
                title={
                  sessionId
                    ? "Terminals"
                    : "Click the plus button to start a session and connect to the terminal"
                }
              >
                <div className={styles.terminalSelect} onClick={handleClick}>
                  {activeTerminal.name.length > 20
                    ? `${activeTerminal.name.slice(0, 20)}...`
                    : activeTerminal.name}
                </div>
              </Tooltip>
            )}
            <Tooltip
              className={styles.minimizeIcon}
              onClick={() => {
                void handleAddTerminal();
                setShowTerminal(true);
              }}
              title="Add Terminal"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Tooltip>

            <Tooltip
              className={styles.minimizeIcon}
              onClick={handleToggleTerminal}
              title={showTerminal ? "Minimize" : "Maximize"}
            >
              <FontAwesomeIcon icon={showTerminal ? faAngleDown : faAngleUp} />
            </Tooltip>
          </div>
        </div>
        <ChamTerminal
          showTerminal={showTerminal && activeTerminal?.id != null}
          setShowTerminal={setShowTerminal}
          terminals={terminals}
          activeTerminalId={activeTerminal?.id}
          shouldRestartTerminal={shouldRestartTerminal}
          notifyRestart={() => {
            setShouldRestartTerminal(false);
            setTerminals([]);
          }}
        />
      </div>
      <TerminalMenu
        anchorEl={anchorEl}
        terminals={terminals}
        setTerminals={setTerminals}
        setAnchorEl={setAnchorEl}
        activeTerminal={activeTerminal}
        setActiveTerminal={setActiveTerminal}
        setShowTerminal={setShowTerminal}
      />
    </>
  );
};
