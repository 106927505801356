import React from "react";
import { ChamEditor } from "../ChamEditor/ChamEditor";
import styles from "./ChamIDEContainer.module.css";
import { IdeContainer } from "./types";
import { useTerminalHighlight } from "../../contexts/TerminalHighlightContext";
import { TerminalContainer } from "./TerminalContainer";
import {
  DeviceType,
  useResponsiveness,
} from "../../../../../hooks/useResponsiveness";

export const DesktopIdeContainer: React.FC<IdeContainer> = ({
  showTerminal,
  setShowTerminal,
  setError,
  handleToggleTerminal,
  toggleShowChamBrowser,
}) => {
  const { isTerminalHighlighted } = useTerminalHighlight();
  const { deviceType } = useResponsiveness();

  return (
    <>
      <div
        className={`${styles.textInputContainer} ${
          styles.textInputContainerFull
        }`}
      >
        <div
          className={
            !showTerminal ? styles.editorSectionFull : styles.editorSectionHalf
          }
        >
          {/* To avoid ResizeObserver bug, comment out below line and uncomment line after it */}
          <ChamEditor toggleShowChamBrowser={toggleShowChamBrowser} />
          {/* {!isDragging ? <ChamEditor /> : <></>} */}
        </div>

        {deviceType === DeviceType.Desktop && (
          <div
            className={`
          ${showTerminal ? styles.terminalContainerOpen : styles.terminalContainerCollapsed}
          ${isTerminalHighlighted ? styles.isTerminalHighlighted : ""}
        `}
          >
            <TerminalContainer
              showTerminal={showTerminal}
              setShowTerminal={setShowTerminal}
              setError={setError}
            />
          </div>
        )}
      </div>
    </>
  );
};
