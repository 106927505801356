import {
  DndProvider,
  MultiBackend,
  Tree,
  getBackendOptions,
  getDescendants,
  type DropOptions,
  type NodeModel,
} from "@minoru/react-dnd-treeview";
import { type Monaco } from "@monaco-editor/react";
import React from "react";
import { moveDirectory } from "../utils/moveDirectory";
import { FileIcon } from "../icons/FileIcon";
import { FolderIcon } from "../icons/FolderIcon";
import {
  INodeTypes,
  ROOT_DIRECTORY,
  getAbsoluteNodePath,
  type TreeNodeDataType,
} from "../../utils";
import Node from "./Node";
import Placeholder from "./Placeholder";
import styles from "./styles.module.css";
import useTreeOpenHandler from "./useTreeOpenHandler";
import {
  DeviceType,
  useResponsiveness,
} from "../../../../../hooks/useResponsiveness";

const reorderArray = (
  array: Array<NodeModel<TreeNodeDataType>>,
  sourceIndex: number,
  targetIndex: number
): Array<NodeModel<TreeNodeDataType>> => {
  const newArray = [...array];
  const element = newArray.splice(sourceIndex, 1)[0];
  newArray.splice(targetIndex, 0, element);
  return newArray;
};

interface IFolderTree {
  setError: (setError: string) => void;
  setOpenedFileId?: (fileId: number | string) => void;
  openedFileId: number | string | undefined;
  treeData?: Array<NodeModel<TreeNodeDataType>>;
  monaco: Monaco | undefined;
  setTreeData?: (tree: Array<NodeModel<TreeNodeDataType>>) => void;
  setTerminalSessionId?: (sessionId: string | undefined) => void;
  handleAddNewDir: (type: INodeTypes) => void;
  canAddNode: boolean;
  setCanAddNode: (canAdd: boolean) => void;
  terminalSessionId?: string | undefined;
  setRefreshMonaco?: (refresh: boolean) => void;
  onOpenFile?: () => void;
}

export const FolderTree: React.FC<IFolderTree> = ({
  setError,
  monaco,
  setOpenedFileId,
  setTerminalSessionId,
  treeData,
  openedFileId,
  canAddNode,
  setCanAddNode,
  setTreeData,
  handleAddNewDir,
  terminalSessionId,
  setRefreshMonaco,
  onOpenFile,
}) => {
  const { ref, getPipeHeight, toggle, open } = useTreeOpenHandler();
  const { deviceType } = useResponsiveness();
  const handleDrop = async (e: DropOptions): Promise<void> => {
    const { dragSourceId, dropTargetId, destinationIndex } = e;
    if (
      typeof dragSourceId === "undefined" ||
      typeof dropTargetId === "undefined"
    )
      return;
    const start = treeData?.find((v) => v.id === dragSourceId);
    const end = treeData?.find((v) => v.id === dropTargetId);

    if (start && terminalSessionId && treeData) {
      const movedDirectory = await moveDirectory(
        `${getAbsoluteNodePath(start, treeData)}/${start.text}`,
        end
          ? `${getAbsoluteNodePath(end, treeData)}/${end.text}/${start.text}`
          : `${ROOT_DIRECTORY}/${start.text}`,
        terminalSessionId
      );
      if (!movedDirectory) {
        setError("Failed to move directory");
        console.error("Failed to move directory");
      } else {
        setRefreshMonaco?.(true);
      }
    }
    if (
      start?.parent === dropTargetId &&
      start &&
      typeof destinationIndex === "number" &&
      treeData
    ) {
      const newTreeData = reorderArray(
        treeData,
        treeData.indexOf(start),
        destinationIndex
      );
      setTreeData?.(newTreeData);
    }

    if (
      start?.parent !== dropTargetId &&
      start &&
      typeof destinationIndex === "number" &&
      treeData
    ) {
      if (
        getDescendants(treeData, dragSourceId).find(
          (el) => el.id === dropTargetId
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        ) ||
        dropTargetId === dragSourceId ||
        (end && !end?.droppable)
      )
        return;
      const newTreeData = reorderArray(
        treeData,
        treeData.indexOf(start),
        destinationIndex
      );
      const movedElement = newTreeData.find((el) => el.id === dragSourceId);
      if (movedElement) movedElement.parent = dropTargetId;

      setTreeData?.(newTreeData);
    }
  };

  // Whenever we click on another node we want any nodes in "pending" state to be removed;
  const handleCleanUpNodes = React.useCallback(
    (node: NodeModel) => {
      if (node.text !== "" && treeData) {
        setCanAddNode(true);
        setTreeData?.(treeData.filter((data) => data.text !== ""));
      }
    },
    [treeData]
  );

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className={styles.wrapper}>
        {treeData?.length ? (
          <Tree
            ref={ref}
            classes={{
              root: styles.treeRoot,
              placeholder: styles.placeholder,
              dropTarget: styles.dropTarget,
              listItem: styles.listItem,
            }}
            tree={treeData}
            sort={false}
            rootId={ROOT_DIRECTORY}
            insertDroppableFirst={false}
            enableAnimateExpand={true}
            onDrop={(
              _treeModel: Array<NodeModel<TreeNodeDataType>>,
              e: DropOptions
            ) => {
              void handleDrop(e);
            }}
            canDrop={() => deviceType === DeviceType.Desktop}
            dropTargetOffset={5}
            placeholderRender={(node, { depth }) => (
              <Placeholder node={node} depth={depth} />
            )}
            render={(node, { depth, isOpen, isDropTarget }) => (
              <Node
                setError={setError}
                getPipeHeight={getPipeHeight}
                node={node}
                depth={depth}
                isOpen={isOpen}
                open={open}
                onClick={() => {
                  handleCleanUpNodes(node);
                  if (node.droppable) {
                    toggle(node.id);
                  } else {
                    if (node.text !== "") {
                      setOpenedFileId?.(node.id);
                      onOpenFile?.();
                    }
                  }
                }}
                canAddNode={canAddNode}
                setCanAddNode={setCanAddNode}
                isDropTarget={isDropTarget}
                treeData={treeData}
                setTreeData={setTreeData}
                openedFileId={openedFileId}
                setOpenedFileId={setOpenedFileId}
                terminalSessionId={terminalSessionId}
                setTerminalSessionId={setTerminalSessionId}
                monaco={monaco}
                setRefreshMonaco={setRefreshMonaco}
              />
            )}
          />
        ) : (
          <NoTreeNodes handleAddNewDir={handleAddNewDir} />
        )}
      </div>
    </DndProvider>
  );
};

const NoTreeNodes: React.FC<{
  handleAddNewDir: (type: INodeTypes) => void;
}> = ({ handleAddNewDir }) => {
  return (
    <div className={styles.noNodes}>
      Add a directory
      <div className={styles.directoryTypes}>
        <div
          className={styles.directoryTypeButton}
          onClick={() => handleAddNewDir(INodeTypes.file)}
        >
          <FileIcon className={styles.noNodeIcon} color={"#0bda5e"} /> File
        </div>
        <div
          className={styles.directoryTypeButton}
          onClick={() => handleAddNewDir(INodeTypes.folder)}
        >
          <FolderIcon className={styles.noNodeIcon} color={"#0bda5e"} /> Folder
        </div>
      </div>
    </div>
  );
};
